import React from "react";
import { useFormik } from "formik";
import { Input } from "reactstrap";
import * as Yup from "yup";

const UpdateInvoiceStatus = (props) => {
    const { S_no, invoice_status } = props;

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: "",
        },
        validationSchema: Yup.object({
            status: Yup.string().required("Please select Status."),
        }),
        onSubmit: values => {
            // 
        },
    });

    React.useEffect(() => {
        validation.setFieldValue("status", invoice_status);
    }, [invoice_status]);

    return (
        <>
            <Input
                name="status"
                type="select"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.status || ""}
                invalid={
                    validation.touched.status &&
                        validation.errors.status
                        ? true
                        : false
                }
            >
                <option value="" disabled>Select Status</option>
                {
                    [{ type: "raised", id: 1 }, { type: "reviewed", id: 2 }].map((status, index) => (
                        <option key={index} value={status.type}>
                            {status.type}
                        </option>
                    ))
                }
            </Input>
        </>
    );
};

export default UpdateInvoiceStatus;
