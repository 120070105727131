const documentsListDummyData = [
  {
    SNo: 1,
    createdAt: "2024-02-26T09:00:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
    used: true,
    usedBy: "moderator",
    usedDate: "2024-02-26T09:00:00.000Z",
    price: 5200,
    invoiceId: 90272708,
  },
  {
    SNo: 2,
    createdAt: "2024-02-25T10:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    used: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    SNo: 3,
    createdAt: "2024-02-24T14:15:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-24T14:15:00.000Z",
    used: true,
    usedBy: "manager",
    usedDate: "2024-02-24T14:15:00.000Z",
    price: 3500,
    invoiceId: 90272709,
  },
  {
    SNo: 4,
    createdAt: "2024-02-23T16:45:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-23T16:45:00.000Z",
    used: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    SNo: 5,
    createdAt: "2024-02-22T11:30:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-22T11:30:00.000Z",
    used: true,
    usedBy: "moderator",
    usedDate: "2024-02-22T11:30:00.000Z",
    price: 4800,
    invoiceId: 90272710,
  },
  {
    SNo: 6,
    createdAt: "2024-02-21T09:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    used: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    SNo: 7,
    createdAt: "2024-02-20T13:45:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-20T13:45:00.000Z",
    used: true,
    usedBy: "manager",
    usedDate: "2024-02-20T13:45:00.000Z",
    price: 3800,
    invoiceId: 90272711,
  },
  {
    SNo: 8,
    createdAt: "2024-02-19T15:30:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-19T15:30:00.000Z",
    used: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    SNo: 9,
    createdAt: "2024-02-18T12:15:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-18T12:15:00.000Z",
    used: true,
    usedBy: "moderator",
    usedDate: "2024-02-18T12:15:00.000Z",
    price: 4900,
    invoiceId: 90272712,
  },
  {
    SNo: 10,
    createdAt: "2024-02-17T10:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    used: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
]

const usageListDummyData = [
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
]

export { documentsListDummyData, usageListDummyData }
