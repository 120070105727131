import React, { useState } from "react"

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap"
import { useFormik } from "formik"

import * as Yup from "yup"
import { toast } from "react-toastify"
import axiosAuthInstance from "../../../axios/axiosAuthInstance"

const SupplierModal = ({ supplierModal, handleSupplierToggle, toggleSupplierUpdated }) => {
  const [supplierName, setSupplierName] = useState("")
  const [supplierBalance, setSupplierBalance] = useState("")
  const [supplierAddress, setSupplierAddress] = useState("")
  const [supplierTel, setSupplierTel] = useState("")
  

  const toggle = () => {
    handleSupplierToggle()
  }
  const updateSupplierList = () => {
    toggleSupplierUpdated()
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      supplierName: supplierName || "",
      supplierBalance: supplierBalance || "",
      supplierAddress: supplierAddress || "",
      supplierTel: supplierTel || "",
    },
    validationSchema: Yup.object({
      supplierName: Yup.string().required("Please Enter Supplier Name."),
      supplierBalance: Yup.number().required("Please enter balance"),
    }),
    onSubmit: async values => {
      const newSupplier = {
        supplierName: values["supplierName"],
        supplierBalance: values["supplierBalance"],
        supplierAddress: values["supplierAddress"],
        supplierTel: values["supplierTel"],
      }
      // save new user
      //dispatch(onAddNewUser(newArea))
      console.log(newSupplier)

      try {
        const safeTransfereRes = await postNewSupplier(newSupplier)
        if (safeTransfereRes.status === 200) {
          toast.dismiss()
          toast.success("Supplier added successfully.")
          updateSupplierList()
          validation.resetForm()
        }
      } catch (err) {
        toast.dismiss()
        toast.error("Problem occured. Please, try again.")
        console.log(err)
      }

      toggle()
    },
  })

  const postNewSupplier = async e => {
    console.log(e)
    const res = await axiosAuthInstance.post("TblSupplier/save", e)
    if (res) {
        console.log(res)
      return res
    }
  }

  return (
    <Modal isOpen={supplierModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Add Supplier
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Supplier Name</Label>
                <Input
                  name="supplierName"
                  type="text"
                  value={validation.values.supplierName || ""}
                  invalid={
                    validation.touched.supplierName &&
                    validation.errors.supplierName
                      ? true
                      : false
                  }
                  placeholder="Enter Supplier Name ..."
                  onChange={(e) => setSupplierName(e.target.value)}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.supplierName &&
                validation.errors.supplierName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.supplierName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Supplier Balance</Label>
                <Input
                  name="supplierBalance"
                  type="number"
                  min={0}
                  value={validation.values.supplierBalance || ""}
                  invalid={
                    validation.touched.supplierBalance &&
                    validation.errors.supplierBalance
                      ? true
                      : false
                  }
                  placeholder="Enter Supplier Balance ..."
                  onChange={(e) => setSupplierBalance(e.target.value)}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.supplierBalance &&
                validation.errors.supplierBalance ? (
                  <FormFeedback type="invalid">
                    {validation.errors.supplierBalance}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Supplier Address</Label>
                <Input
                  name="supplierAddress"
                  type="text"
                  value={supplierAddress}
                  placeholder="Enter Supplier Address ..."
                  onChange={e => setSupplierAddress(e.target.value)}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">Supplier phone</Label>
                <Input
                  name="supplierTel"
                  type="text"
                  value={supplierTel}
                  placeholder="Enter Supplier phone ..."
                  onChange={e => setSupplierTel(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div className="text-end">
            <button type="submit" className="btn btn-success save-user">
              <i className="mdi mdi-check-circle-outline me-1" />
              Save
            </button>
            <Button
              type="button"
              color="secondary"
              className="mx-1"
              onClick={toggle}
            >
              <i className="mdi mdi-cancel me-1" />
              Cancel
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default SupplierModal
