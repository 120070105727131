import React from "react"
import { Badge, Col, Modal, ModalHeader, Row } from "reactstrap"

import { convertDate } from "pages/add-paper/utils/convertDate"

const UsageDetails = props => {
  const { modal, toggle, usageData } = props

  return (
    <Modal isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        Usage Details
      </ModalHeader>
      <Row>
        <Col lg="12" className="mt-2 mx-3">
          <Row>
            <Col lg="6">
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>Usage Status: </span>
                <span style={{ fontWeight: "500" }}>
                  <Badge
                    className={`p-2 
                             ${usageData.used ? "bg-success" : "bg-danger"}`}
                  >
                    {usageData.used ? "used" : "Not used"}
                  </Badge>
                </span>
              </div>
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>Usage Date: </span>
                <span style={{ fontWeight: "500" }}>
                  {convertDate(usageData.usedDate)}
                </span>
              </div>
            </Col>
            <Col lg="6">
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>used By: </span>
                <span style={{ fontWeight: "500" }}>{usageData.usedBy}</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default UsageDetails
