import React, { useCallback, useMemo, useState } from "react"
import TableContainer from "../../components/Common/TableContainer"
import { Col, Row, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import UpdateStatusInput from "pages/invoices-list/selectInputs/updateTicketStatus"
import UpdateInvoiceStatus from "pages/invoices-list/selectInputs/updateInvoiceStatus"
import InvoiceModal from "pages/invoices-list/invoiceModal"
import { invoicesListDummyData } from "pages/data"

const InvoicesList = () => {
  const [invoiceId, setInvoiceId] = useState("")
  const [modal1, setModal1] = useState(false)
  const [showSpecificInvoice, setShowSpecificInvoice] = useState(false)

  const closeViewModal = () => setModal1(prevState => !prevState)
  const openViewModal = useCallback(id => {
    setOrderMasterId(id)
    closeViewModal()
  }, [])

  const toggleShowSpecificInvoice = useCallback(() => {
    setShowSpecificInvoice(prevState => !prevState)
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "S no",
        accessor: "S_no",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Manual Invoice no",
        accessor: "Manual_Invoice_no",
        filterable: true,
        Cell: cellProps => {
          const { S_no } = cellProps.row.original
          return (
            <Link
              to={`?invoice_id=${S_no}`}
              onClick={toggleShowSpecificInvoice}
              className="badge badge-soft-primary font-size-11 m-1"
            >
              {cellProps.value || ""}
            </Link>
          )
        },
      },
      {
        Header: "Date",
        accessor: "Date",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "time",
        accessor: "time",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "updated at",
        accessor: "updated_at",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Prepared by",
        accessor: "Prepared_by",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "updated by",
        accessor: "updated_by",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Customer Name",
        accessor: "Customer_Name",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "purchase Type",
        accessor: "purchase_Type",
        filterable: true,
        Cell: cellProps => {
          const { market_Place_Purchased } = cellProps.row.original
          return (
            <>
              {market_Place_Purchased
                ? market_Place_Purchased
                : cellProps.value}
            </>
          )
        },
      },
      {
        Header: "Total Price",
        accessor: "Total_Price",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Total Discount",
        accessor: "Total_Discount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Line Total",
        accessor: "Line_Total",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "invoice status",
        accessor: "invoice_status",
        Cell: cellProps => {
          const { S_no, invoice_status } = cellProps.row.original
          return (
            <UpdateInvoiceStatus invoice_status={invoice_status} S_no={S_no} />
          )
        },
      },
      {
        Header: "ticket status",
        accessor: "ticket_status",
        filterable: true,
        Cell: cellProps => {
          const { S_no, ticket_status } = cellProps.row.original
          return <UpdateStatusInput ticket_status={ticket_status} S_no={S_no} />
        },
      },
    ],
    []
  )

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={invoicesListDummyData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={5}
                allListSize={invoicesListDummyData.length}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <InvoiceModal
        modal={showSpecificInvoice}
        toggle={toggleShowSpecificInvoice}
      />
    </>
  )
}

export default InvoicesList
