import React, { useContext } from "react"
import classes from "../index.module.scss"
import SingleTabelRow from "pages/add-invoice/paymentSummary/singleTabelRow"
import { Input } from "reactstrap"

import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"

const PaymentSummary = props => {
  const { totalLineTotal, topTabelRowsData } = props

  const paymentSummaryCtx = useContext(PaymentSummaryContext)
  const { totalAmout } = paymentSummaryCtx

  return (
    <div className={classes.paymentSummary}>
      {Array.from({ length: 7 }, (_, index) => index + 1).map((one, index) => (
        <SingleTabelRow
          key={one}
          index={index}
          rowNumber={one}
          topTabelRowsData={topTabelRowsData}
        />
      ))}
      <div style={{ display: "flex", paddingTop: "15px" }}>
        <div style={{ marginLeft: "640px" }}>
          <Input
            type="number"
            value={totalAmout === 0 ? "" : totalAmout}
            className={classes.transparent_input}
            readOnly
          />
        </div>
        <div style={{ marginLeft: "220px" }}>
          <Input
            type="number"
            value={
              (!totalLineTotal || !totalAmout) &&
              totalLineTotal - totalAmout === 0
                ? ""
                : totalLineTotal - totalAmout
            }
            className={classes.transparent_input}
            readOnly
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentSummary
