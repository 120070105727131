import React from 'react'

const NetProfit = () => {
  return (
    <div>
      NetProfit
    </div>
  )
}

export default NetProfit
