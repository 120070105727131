import TableContainer from "components/Common/TableContainer";
import React, { useMemo } from "react";
import { Card, CardBody, Col, Modal, ModalHeader, Row } from "reactstrap";


const headerDummyData = {
    "salesType": "cash",
    "preparedBy": "Mahmoud korim mohamed kandel",
    "date": "07 Jan, 2024",
    "time": "18:16:54",
    "customerName": "Mahmoud kandel",
    "customerNo": "10",
    "mobile": "011121111111",
    "email": "mahmoudkandel980@gmail.com",
    "purchaseType": "onsite-purchase",
    "marketPlacePurchased": ""
};

const invoiceDummyData = [
    {
        "rowNumber": 1,
        "itemCode": 123456789123456,
        "description": "test",
        "unitPrice": 5000,
        "qty": 5,
        "totalPrice": 25000,
        "lineTotal": 22500,
        "discount": "10%"
    },
    {
        "rowNumber": 2,
        "itemCode": 123456789123456,
        "description": "test",
        "unitPrice": 600,
        "qty": 10,
        "totalPrice": 6000,
        "lineTotal": 5000,
        "discount": 1000
    },
    {
        "rowNumber": 3,
        "itemCode": 123456789123456,
        "description": "test",
        "unitPrice": 500,
        "qty": 5,
        "totalPrice": 2500,
        "lineTotal": 2500,
        "discount": 0
    }
];

const InvoiceModal = (props) => {
    const { modal, toggle } = props;

    const columns = useMemo(
        () => [
            {
                Header: 'Row Number',
                accessor: 'rowNumber',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Item Code',
                accessor: 'itemCode',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Description',
                accessor: 'description',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Unit Price',
                accessor: 'unitPrice',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Qty',
                accessor: 'qty',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Total Price',
                accessor: 'totalPrice',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Discount',
                accessor: 'Discount',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: 'Line Total',
                accessor: 'lineTotal',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
        ],
        []
    );

    return (
        <Modal isOpen={modal} toggle={toggle} size="xl">
            <ModalHeader toggle={toggle} tag="h4">
                Invoice
            </ModalHeader>
            <Row>
                <Col lg="12" className="mt-2 mx-3">
                    <Row>
                        <Col lg="6" >
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Sales Type: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.salesType}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Prepared By: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.preparedBy}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Date: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.date}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Time: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.time}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Purchase Type: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.purchaseType}</span>
                            </div>
                            {headerDummyData.marketPlacePurchased
                                ?
                                <div className="flex justify-content-between mb-3">
                                    <span style={{ fontWeight: "600" }}>Market Place Purchased: </span>
                                    <span style={{ fontWeight: "500" }}>{headerDummyData.marketPlacePurchased}</span>
                                </div>
                                :
                                null}
                        </Col>
                        <Col lg="6">
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Customer Name: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.customerName}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Customer No: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.customerNo}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Mobile: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.mobile}</span>
                            </div>
                            <div className="flex justify-content-between mb-3">
                                <span style={{ fontWeight: "600" }}>Email: </span>
                                <span style={{ fontWeight: "500" }}>{headerDummyData.email}</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns}
                                data={invoiceDummyData}
                                isGlobalFilter={true}
                                isAddOptions={false}
                                customPageSize={5}
                                allListSize={invoiceDummyData.length}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
};

export default InvoiceModal;
