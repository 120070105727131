import React, { useRef, useContext, useEffect } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import invoiceImageWithWaterMark from "./invoice-image-water-mark.jpg"
import HeaderInputs from "pages/add-invoice/headerInputs"
import classes from "./index.module.scss"
import SingleTabelRow from "pages/add-invoice/tabel/singleTabelRow"
import TabelFooter from "pages/add-invoice/tabel/tabelFooter"
import PrintButton from "pages/add-invoice/printButton"
import PaymentSummary from "pages/add-invoice/paymentSummary/PaymentSummary"
import NonPrintableContainer from "pages/add-invoice/notInPrint/nonPrintableContainer"

import PaymentSummaryContextProvider from "pages/add-invoice/context/paymentSummaryContext"
import TabelContextProvider, {
  TabelContext,
} from "pages/add-invoice/context/tabelContext"
import DocumentIdContextProvider from "pages/add-invoice/context/documentIdContext"

import Submit from "pages/add-invoice/printInvoice/submit"

const Invoice = () => {
  document.title = "Dragon | add invoice"
  const printContentRef = useRef()

  const tabelCtx = useContext(TabelContext)
  const { invoiceData, totalData } = tabelCtx

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Invoices" breadcrumbItem="Add Invoice" />
        {/* <PrintButton printContentRef={printContentRef.current} /> */}
        <Submit printContentRef={printContentRef.current} />
      </Container>
      <Container fluid style={{ overflowX: "scroll" }}>
        <div
          className={classes.invoice_main_page_container}
          style={{ width: "1200px", position: "relative" }}
        >
          <img
            className={classes.invoice_main_page_image}
            src={invoiceImageWithWaterMark}
          />
          <div
            ref={printContentRef}
            className={classes.invoice_main_page_inputs}
          >
            <HeaderInputs />
            <div style={{ marginTop: "50px" }}>
              {Array.from({ length: 12 }, (_, index) => index + 1).map(
                (one, index) => (
                  <SingleTabelRow key={one} index={index} rowNumber={one} />
                )
              )}
            </div>
            <TabelFooter />

            <>
              <PaymentSummary
                totalLineTotal={totalData.lineTotal}
                topTabelRowsData={invoiceData.tabelData.filter(
                  one => one.itemCode && one.totalPrice && one.lineTotal
                )}
              />
            </>
          </div>
          <NonPrintableContainer />
        </div>
      </Container>
    </div>
  )
}

const InvoicePage = () => {
  return (
    <TabelContextProvider>
      <PaymentSummaryContextProvider>
        <DocumentIdContextProvider>
          <Invoice />
        </DocumentIdContextProvider>
      </PaymentSummaryContextProvider>
    </TabelContextProvider>
  )
}

export default InvoicePage
