import React, { useMemo, useState } from "react"
import TableContainer from "../../components/Common/TableContainer"
import {
  Col,
  Row,
  Card,
  CardBody,
  Badge,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { documentsListDummyData } from "pages/data"

import AssignDetails from "pages/add-paper/assignDetails"
import UsageDetails from "pages/add-paper/usageDetails"
import UpdateAssignStatus from "pages/add-paper/updateAssignStatus"
import { convertDate } from "pages/add-paper/utils/convertDate"

const DocumentsList = () => {
  const [assignModel, setAssignModel] = useState(false)
  const [usageModel, setUsageModel] = useState(false)
  const [assignData, setAssignData] = useState({})
  const [usageData, setUsageData] = useState({})

  const toggleAssignModel = () => setAssignModel(prevState => !prevState)
  const toggleUsageModel = () => setUsageModel(prevState => !prevState)

  const columns = useMemo(
    () => [
      {
        Header: "S no",
        accessor: "SNo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Created At",
        accessor: "createdAt",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value ? convertDate(cellProps.value) : ""}</>
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Assign Status",
        accessor: "assigned",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Badge
                className={`p-2
                             ${cellProps.value ? "bg-success" : "bg-danger"}`}
              >
                {cellProps.value ? "Assigned" : "Not Assigned"}
              </Badge>
            </>
          )
        },
      },
      {
        // UpdateAssignStatus
        Header: "Assigned To",
        accessor: "assignedTo",
        filterable: true,
        Cell: cellProps => {
          const { SNo, assignedTo } = cellProps.row.original
          return <UpdateAssignStatus assignedTo={assignedTo} SNo={SNo} />
        },
      },
      {
        Header: "Assign Details",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    if (rowData.assigned) {
                      setAssignData(rowData)
                      toggleAssignModel()
                    }
                  }}
                  style={{
                    backgroundColor: !rowData.assigned ? "gray" : "",
                  }}
                  disabled={!rowData.assigned}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltipAssign" />
                  <UncontrolledTooltip
                    placement="top"
                    target="viewtooltipAssign"
                  >
                    View Assign Details
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
      {
        Header: "Usage Status",
        accessor: "used",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Badge
                className={`p-2
                             ${cellProps.value ? "bg-success" : "bg-danger"}`}
              >
                {cellProps.value ? "used" : "Not used"}
              </Badge>
            </>
          )
        },
      },
      {
        Header: "Invoice Serial",
        accessor: "invoiceId",
        filterable: true,
        Cell: cellProps => {
          return (
            <Link
              to={`/invoices?invoice_id=${cellProps.value}`}
              className="badge badge-soft-primary font-size-11 m-1"
            >
              {cellProps.value || ""}
            </Link>
          )
        },
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Usage Details",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    if (rowData.used) {
                      setUsageData(rowData)
                      toggleUsageModel()
                    }
                  }}
                  style={{
                    backgroundColor: !rowData.used ? "gray" : "",
                  }}
                  disabled={!rowData.used}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View Usage Details
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={documentsListDummyData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={5}
                allListSize={documentsListDummyData.length}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <AssignDetails
        assignData={assignData}
        modal={assignModel}
        toggle={toggleAssignModel}
      />
      <UsageDetails
        usageData={usageData}
        modal={usageModel}
        toggle={toggleUsageModel}
      />
    </>
  )
}

export default DocumentsList
