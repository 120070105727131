import React, { useState, useEffect } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"

const usersDummyData = [
  "SuperAdmin",
  "Admin",
  "subAdmin",
  "manager",
  "moderator",
]

const CreatePaper = props => {
  const { modal, toggle } = props
  const [selectedUser, setSelectedUser] = useState([])
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [formValues, setFormValues] = useState({
    values: { paperDocId1: "" },
    errors: { paperDocId1: Yup.string().required("Paper Id is required") },
  })

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: formValues.values,
    validationSchema: Yup.object().shape(formValues.errors),
    onSubmit: values => {
      // TODO: submit values
      toggle()
    },
  })

  // make sure when validation values changed formValues change too
  useEffect(() => {
    setFormValues(prevState => ({
      ...prevState,
      values: validation.values,
    }))
  }, [validation.values])

  const onAddFormRow = () => {
    const nextDoc = formRows.length + 1
    const modifiedRows = [...formRows]
    modifiedRows.push({ id: Math.random() })
    setFormRows(modifiedRows)

    setFormValues(prevState => ({
      values: {
        ...prevState.values,
        [`paperDocId${nextDoc}`]: "",
      },
      errors: {
        ...prevState.errors,
        [`paperDocId${nextDoc}`]: Yup.string().required("Paper Id is required"),
      },
    }))
  }

  const onDeleteFormRow = id => {
    if (id !== 1) {
      let modifiedRows = [...formRows]
      const index = modifiedRows.findIndex(x => x["id"] === id)

      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)

      const removeObjectPropertyByIndex = (object, index) => {
        if (index >= 0 && index < Object.keys(object).length) {
          const propertyToRemove = Object.keys(object)[index]
          delete object[propertyToRemove]
        }
        return object
      }
      let values = { ...formValues.values },
        errors = { ...formValues.errors }

      const newValues = removeObjectPropertyByIndex(values, index)
      const newErrors = removeObjectPropertyByIndex(errors, index)
      setFormValues({ values: newValues, errors: newErrors })
    }
  }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Add Paper Id
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row className="gap-2">
            {(formRows || []).map((formRow, key) => (
              <Row key={key} className="align-items-start">
                <Col className="mb-3">
                  <Label className="form-label">Document Id</Label>
                  <Input
                    name={`paperDocId${key + 1}`}
                    type="text"
                    placeholder="Insert New Paper Document Id"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values[`paperDocId${key + 1}`] || ""}
                    invalid={
                      validation.touched[`paperDocId${key + 1}`] &&
                      validation.errors[`paperDocId${key + 1}`]
                    }
                  />
                  {validation.touched[`paperDocId${key + 1}`] &&
                  validation.errors[`paperDocId${key + 1}`] ? (
                    <FormFeedback type="invalid">
                      {validation.errors[`paperDocId${key + 1}`]}
                    </FormFeedback>
                  ) : null}
                </Col>

                <Col className="mb-3">
                  <Label className="form-label">User</Label>
                  <Select
                    value={selectedUser[key + 1]}
                    onChange={selectedOption => {
                      setSelectedUser(prevState => {
                        const newState = [...prevState]
                        newState[key + 1] = selectedOption
                        return newState
                      })
                    }}
                    isMulti={true}
                    options={usersDummyData.map(one => ({
                      value: one,
                      label: one,
                    }))}
                    className="select2-selection"
                  />
                </Col>

                <Col xs="auto" className="align-self-center">
                  <div className="d-grid">
                    <button
                      className={`btn btn-danger py-1 ${
                        validation.touched[`paperDocId${key + 1}`] &&
                        validation.errors[`paperDocId${key + 1}`]
                          ? "mb-1"
                          : "mt-2"
                      }`}
                      onClick={() => onDeleteFormRow(formRow.id)}
                    >
                      <i className="mdi mdi-delete font-size-16"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            ))}
            <Col xs="auto">
              <button
                className="btn btn-success btn-label mx-1"
                onClick={() => onAddFormRow()}
              >
                <>
                  <i className="bx bx-plus label-icon"></i> Add New Paper
                </>
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex gap-2 mt-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-label mx-1 mb-2"
                >
                  <>
                    <i className="bx bx-check label-icon"></i>
                    Submit
                  </>
                </button>
                <button
                  onClick={toggle}
                  className="btn btn-outline-primary btn-label mx-1 mb-2"
                >
                  <>
                    <i className="bx bx-x label-icon"></i>
                    Cancel
                  </>
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CreatePaper
