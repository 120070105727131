import React from 'react'

const SafeBalance = () => {
  return (
    <div>
      SafeBalance
    </div>
  )
}

export default SafeBalance
