import React, { useEffect, useContext } from "react"
import classes from "../index.module.scss"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormFeedback, Input } from "reactstrap"

import { TabelContext } from "pages/add-invoice/context/tabelContext"

const SingleTabelRow = props => {
  const { index } = props
  const [discount, setDiscount] = React.useState(0)
  const [invalidDiscount, setInvalidDiscount] = React.useState(false)

  const tabelCtx = useContext(TabelContext)
  const { updateTabelDataHandler, activeRow } = tabelCtx

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowNumber: props.rowNumber,
      itemCode: "",
      description: "",
      unitPrice: "",
      qty: "",
      totalPrice: "",
      lineTotal: "",
    },
    // validationSchema: Yup.object({
    //     rowNumber: Yup.number().required("Please Enter No"),
    //     itemCode: Yup.number().required("Please Enter item code"),
    //     description: Yup.string().required("Please Enter Description"),
    //     unitPrice: Yup.number().required("Please Enter Unit Price"),
    //     qty: Yup.number().required("Please Enter Qty"),
    //     totalPrice: Yup.number().required("Please Enter Total Price"),
    //     lineTotal: Yup.number().required("Please Enter Line Total"),
    // }),
    onSubmit: values => {
      //
    },
  })

  React.useEffect(() => {
    if (validation.values.qty === 0 || validation.values.unitPrice === 0) {
      validation.setFieldValue("totalPrice", 0)
      validation.setFieldValue("lineTotal", 0)
      return
    }

    if (validation.values.qty && validation.values.unitPrice) {
      validation.setFieldValue(
        "totalPrice",
        validation.values.qty * validation.values.unitPrice
      )
    }
  }, [validation.values.qty, validation.values.unitPrice])

  React.useEffect(() => {
    if (validation.values.totalPrice && discount) {
      if (discount.toString().includes("%")) {
        const numberWithOutPercent = discount.toString().split("%")[0]
        const valueToCheck = +numberWithOutPercent
        const total =
          +validation.values.totalPrice -
          (+validation.values.totalPrice * valueToCheck) / 100
        validation.setFieldValue("lineTotal", total)
      } else {
        const total = +validation.values.totalPrice - +discount
        validation.setFieldValue("lineTotal", total)
      }
    } else if (validation.values.totalPrice && !discount) {
      validation.setFieldValue("lineTotal", validation.values.totalPrice)
    }
  }, [discount, validation.values.totalPrice])

  // move tabel row data to parent
  useEffect(() => {
    updateTabelDataHandler({
      rowNumber: validation.values.rowNumber,
      itemCode: validation.values.itemCode,
      description: validation.values.description,
      unitPrice: validation.values.unitPrice,
      qty: validation.values.qty,
      totalPrice: validation.values.totalPrice,
      lineTotal: validation.values.lineTotal,
      discount: discount,
    })
  }, [validation.values, discount])

  const discountChangeHandler = e => {
    let valueToCheck = +e.target.value
    if (!validation.values.qty || !validation.values.unitPrice) return

    if (/^\d+$/.test(e.target.value)) {
      if (valueToCheck > validation.values.qty * validation.values.unitPrice) {
        setInvalidDiscount(true)
      } else {
        setDiscount(valueToCheck)
        setInvalidDiscount(false)
      }
    } else if (
      /^\d+%$/.test(e.target.value) &&
      e.target.value.split("%").length === 2
    ) {
      const numberWithOutPercent = e.target.value.split("%")[0]
      valueToCheck = +numberWithOutPercent
      if (valueToCheck > 100) {
        setInvalidDiscount(true)
      } else {
        setDiscount(`${valueToCheck}%`)
        setInvalidDiscount(false)
      }
    } else if (+e.target.value === 0) {
      setDiscount(0)
      setInvalidDiscount(false)
    }
  }

  return (
    <div style={{ display: "flex", paddingTop: "15px" }}>
      <div style={{ width: "30px", marginLeft: "53px" }}>
        <span className={classes.transparent_input}>
          {validation.values.rowNumber}
        </span>
      </div>
      <div style={{ width: "160px" }}>
        <Input
          id="itemCode"
          name="itemCode"
          type="number"
          min={0}
          inputMode="numeric"
          className={classes.transparent_input_itemCode}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          disabled={index + 1 > activeRow}
          value={
            validation.values.itemCode || validation.values.itemCode === 0
              ? validation.values.itemCode
              : ""
          }
          invalid={
            validation.touched.itemCode && validation.errors.itemCode
              ? true
              : false
          }
        />
      </div>
      <div style={{ width: "405px", marginLeft: "-25px" }}>
        <Input
          id="description"
          name="description"
          type="text"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.description || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.description && validation.errors.description
              ? true
              : false
          }
        />
      </div>
      <div style={{ width: "120px", marginLeft: "20px" }}>
        <Input
          id="unitPrice"
          name="unitPrice"
          type="number"
          min={0}
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          disabled={index + 1 > activeRow}
          value={
            validation.values.unitPrice || validation.values.unitPrice === 0
              ? validation.values.unitPrice
              : ""
          }
          invalid={
            validation.touched.unitPrice && validation.errors.unitPrice
              ? true
              : false
          }
        />
      </div>
      <div style={{ width: "75px", marginLeft: "5px" }}>
        <Input
          id="qty"
          name="qty"
          type="number"
          min={0}
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          disabled={index + 1 > activeRow}
          value={
            validation.values.qty || validation.values.qty === 0
              ? validation.values.qty
              : ""
          }
          invalid={
            validation.touched.qty && validation.errors.qty ? true : false
          }
        />
      </div>
      <div style={{ width: "125px", marginLeft: "5px" }}>
        <Input
          id="totalPrice"
          name="totalPrice"
          type="number"
          min={0}
          className={classes.transparent_input}
          readOnly
          value={validation.values.totalPrice}
          disabled={index + 1 > activeRow}
        />
      </div>
      <div style={{ width: "75px", marginLeft: "5px" }}>
        <Input
          id="discount"
          name="discount"
          type="text"
          inputMode="numeric"
          min={0}
          className={classes.transparent_input}
          onChange={discountChangeHandler}
          value={discount ? discount : ""}
          invalid={invalidDiscount}
          disabled={index + 1 > activeRow}
        />
      </div>
      <div style={{ width: "120px", marginLeft: "10px" }}>
        <Input
          id="lineTotal"
          name="lineTotal"
          type="number"
          min={0}
          className={classes.transparent_input}
          readOnly
          value={validation.values.lineTotal}
          disabled={index + 1 > activeRow}
        />
      </div>
    </div>
  )
}

export default SingleTabelRow
