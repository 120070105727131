import React from "react"
import { useFormik } from "formik"
import { Input } from "reactstrap"
import * as Yup from "yup"

const UpdateAssignStatus = props => {
  const { S_no, assignedTo } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Please select Status."),
    }),
    onSubmit: values => {
      //
    },
  })

  React.useEffect(() => {
    validation.setFieldValue("status", assignedTo)
  }, [assignedTo])

  return (
    <>
      <Input
        name="status"
        type="select"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.status || ""}
        invalid={
          validation.touched.status && validation.errors.status ? true : false
        }
      >
        <option value="" disabled>
          Select Status
        </option>
        {[
          { type: "SuperAdmin", id: 1 },
          { type: "Admin", id: 2 },
          { type: "subAdmin", id: 3 },
          { type: "manager", id: 4 },
          { type: "moderator", id: 5 },
        ].map((status, index) => (
          <option key={index} value={status.type}>
            {status.type}
          </option>
        ))}
      </Input>
    </>
  )
}

export default UpdateAssignStatus
