import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_ITEMS } from "./actionTypes"

import {
  getItemsSuccess,
  getItemsFail,
  getUsersSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"

//Include Both Helper File with needed methods
// import {
//   getUsers,
//   getUserProfile,
//   addNewUser,
//   updateUser,
//   deleteUser,
// } from "../../../helpers/fakebackend_helper"

import { getItems } from "../../../helpers/backend_helper"

function* fetchItems() {
  try {
    const response = yield call(getItems)
    yield put(getItemsSuccess(response.rs.data))
  } catch (err) {
    yield put(getItemsFail(err))
  }
}

function* itemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems)
}

export default itemsSaga
