const invoicesListDummyData = [
  {
    S_no: 1,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Onsite Purchase",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 2,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "talabat",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 3,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "avey",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 4,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Onsite Purchase",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 5,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 6,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 7,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 8,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Delivery",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 9,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Delivery",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 10,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "snoonu",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
  {
    S_no: 11,
    Manual_Invoice_no: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    Prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "rafeeq",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: "raised",
    ticket_status: "raised",
  },
]
export { invoicesListDummyData }
